<template>
    <div>
        待定
    </div>
</template>
<script>
    export default {
        async created() {
            Object.assign(this.itemExt,this.item);
        },
        components:{
            
        },
        props: ['item'],
        data() {
            return {
                itemExt: {
                },
                rules: {

                }
            }
        },
        methods: {
            validForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.ruleForm.validate((valid) => {
                        if (valid) {
                            resolve(true);
                        } else {
                            return reject();
                        }
                    });
                });
            }
        }
    }
</script>
<style scoped>

</style>
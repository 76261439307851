<template>
    <div v-if="item" class="flex flex-v" style="height: 100%;">
        <div class="page-header">{{ item.id ? '编辑商品':'新建商品' }}</div>
        <div class="anchor-tabs">
            <a-anchor :affix="false" :getContainer="getScrollContainer">
                <a-anchor-link href="#item_base_info" title="基本信息" />
                <a-anchor-link href="#item_ext_info" title="扩展信息" />
                <a-anchor-link href="#item_ext_desc" title="宝贝描述" />
            </a-anchor>
        </div>
        <div ref="item_scroll" class="flex-1 content">
            <div id="item_base_info">
                <div class="block">
                    <div class="header">基本信息</div>
                    <div class="body">
                        <ItemBase ref="baseChild" :item.sync="item"></ItemBase>
                    </div>
                </div>
            </div>
            <div id="item_ext_info">
                <div class="block">
                    <div class="header">扩展信息</div>
                    <div class="body">
                        <ItemExtend :item.sync="item"></ItemExtend>
                    </div>
                </div>
            </div>
            <div id="item_ext_desc">
                <div class="block">
                    <div class="header">宝贝描述</div>
                    <div class="body">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" 
                        v-model="item.desc"> </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <el-button type="warning" plain>存草稿</el-button>
            <el-button type="warning" plain @click="submit">发布</el-button>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { getFactoryItem } from '@/service/mall';
    import { addItem } from '@/service/taobao';
    import ItemBase from './detail/Base.vue';
    import ItemExtend from './detail/Extend.vue';
    export default {
        components: {
            ItemBase,
            ItemExtend
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                shopId: this.$route.params.shop_id,
                productId:this.$route.params.product_id,
                item: null,
            }
        },
        async created() {
            this.refreshItems();
        },
        methods: {
            getScrollContainer() {
                return this.$refs.item_scroll
            },
            refreshItems() {
                getFactoryItem(this.productId).then(rst=>{
                    this.item=rst;
                    // this.item.size = JSON.parse(this.item.size);
                    // let color = [];
                    // this.item.fashions.forEach(function(e) {
                    //     color.push(JSON.parse(e.color).color);
                    // })
                    // this.item.colors = color;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            submit:_.debounce(function () {
                // "location.state":"浙江",
                    // "location.city":"杭州",
                    // "stuff_status":"second",
                    // "num":"999",
                    // "price":"999",
                    // "type":"fixed",
                    // "title":"测试商品请不要拍",
                    // "desc":"这是一个好商品",
                    // "cid":"50011150"
                addItem(this.shopId,this.item.id, {
                    num:this.$refs.baseChild.item.count,
                    price:this.$refs.baseChild.item.price,
                    title:this.$refs.baseChild.item.name,
                    image:this.imgUrl+this.$refs.baseChild.item.photoRender[0],
                    desc:this.$refs.baseChild.item.desc,
                }).then(rst=>{
                    console.log(666,rst);
                    this.$message.success("上传成功");
                    this.$router.go(-1);
                }).catch(err=>{
                    console.log(err);
                    this.$message.error(err.message);
                });
            },1000),
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .anchor-tabs {
        box-shadow: 0px 0px 4px 0px rgba(204, 204, 204, 1);
    }

    .anchor-tabs>>>.ant-anchor {
        padding: 0 !important;
    }

    .anchor-tabs>>>.ant-anchor-link {
        float: left;
        padding: 20px 15px;
    }

    .anchor-tabs>>>.ant-anchor-link-active {
        background: rgba(249, 246, 241, 1);
        color: rgba(204, 153, 90, 1);
        border-top: 2px solid rgba(204, 153, 90, 1);
    }

    .content {
        overflow: auto;
    }

    .content>div {
        padding: 30px;
    }

    .content>div:not(:last-child) {
        padding-bottom: 0;
    }

    .content>div>div {
        background: rgba(255, 255, 255, 1);
    }

    .footer {
        padding: 10px;
        background: rgba(255, 255, 255, 1);
        border-top: 2px solid rgba(204, 153, 90, 1);
        text-align: center;
    }
</style>
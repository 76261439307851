<template>
    <div v-if="ruleForm">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="品牌" prop="brand">
                        <el-input v-model="ruleForm.brand"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="材质" prop="brand">
                        <!-- <el-select  v-model="ruleForm.set_no"  placeholder="请选择商品分类">
                            <el-option v-for="item in series" :key="item.set_no" :label="item.name" :value="item.set_no">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="适用人群" prop="people">
                        <el-input v-model="ruleForm.people"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="商品风格" prop="people">
                        <el-select v-model="ruleForm.style">
                            <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="商品分类" prop="cat">
                        <!-- <el-cascader :options="cats" :show-all-levels="false"
                            :props="{value:'id',label:'name',emitPath:false}" v-model="ruleForm.catId">
                        </el-cascader> -->
                        <el-cascader :options="cats" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                            v-model="ruleForm.catId" placeholder="请选择">
                        </el-cascader>
                        <!-- <div class="flex" style="height: 300px;">
                            <div class="flex-1" style="overflow: auto;height: 100%;">
                                <div v-for="(cat,idx) in taobaoCats" :key="idx" @click="selectCat(cat.cid,'taobaoCats1')" style="cursor: pointer;">{{cat.name}}({{cat.cid}})</div>
                            </div>
                            <div class="flex-1" style="overflow: auto;height: 100%;">
                                <div v-for="(cat,idx) in taobaoCats1" :key="idx" @click="selectCat(cat.cid,'taobaoCats2')" style="cursor: pointer;">{{cat.name}}({{cat.cid}})</div>
                            </div>
                            <div class="flex-1" style="overflow: auto;height: 100%;">
                                <div v-for="(cat,idx) in taobaoCats2" :key="idx" style="cursor: pointer;">{{cat.name}}({{cat.cid}})</div>
                            </div>
                        </div> -->
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="商品标题" prop="name" required>
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="一口价" prop="price" required>
                        <el-input v-model="ruleForm.price"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="数量" prop="count">
                        <el-input v-model="ruleForm.count"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-form-item label="商品图片">
                <UploadImgs v-if="ruleForm.fashions" v-model="ruleForm.fashions[0].photos"></UploadImgs>
            </el-form-item> -->
            <el-form-item label="商品图片">
                <UploadImgs v-if="ruleForm.photoRender && ruleForm.photoRender.length>0" 
                    v-model="ruleForm.photoRender"></UploadImgs>
                <!-- <UploadImgs v-else v-model="ruleForm.designPhotos"></UploadImgs> -->
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import { getCats,getStyles,getSets } from '@/service/item';
    import { fetchTree } from '@/util';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components:{
            UploadImgs
        },
        props: ['item'],
        data() {
            return {
                cats: [],
                taobaoCats:[],
                taobaoCats1:[],
                taobaoCats2:[],
                styles: [],
                series:[],
                ruleForm: {},
                rules: {
                    name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
                    price: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
                },
                price_type:0,
            }
        },
        watch: {
            taobaoCats(newName, oldName) {
                this.taobaoCats1=[];
                this.taobaoCats2=[];
            },
            taobaoCats1(newName, oldName) {
                this.taobaoCats2=[];
            }
        },
        async created() {
            // this.taobaoCats = await getCats();
            this.taobaoCats = fetchTree(await getCats(), null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            // console.log(123,this.taobaoCats);
            this.styles = await getStyles();
            this.getSeries();
        },
        mounted() {
            this.ruleForm = this.item;
            // Object.assign(this.ruleForm,this.item);
            console.log("111",this.ruleForm);
            console.log(this.item);
        },
        methods: {
            getSeries() {
                getSets({price_type:this.price_type}).then(rst => {
                    this.series = rst;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            async selectCat(cid,catsName){
                this[catsName] = await getCats(cid);
            },
            validForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.ruleForm.validate((valid) => {
                        if (valid) {
                            resolve(true);
                        } else {
                            return reject();
                        }
                    });
                });
            }
        }
    }
</script>
<style scoped>

</style>